import '@fontsource-variable/open-sans'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import 'iconify-icon'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createApp, h } from 'vue'

import { loadLanguageFile } from './i18n'
import './tailwind.css'
import sentryVuePlugin from '@/app/sentry'

import type { DefineComponent } from 'vue'

const appName = globalThis.document.querySelectorAll('title')[0].textContent ?? 'Laravel'
const lang = globalThis.document.documentElement.lang as App.Enums.Locale

// eslint-disable-next-line unicorn/prefer-top-level-await
void loadLanguageFile(lang).then(() => {
  void createInertiaApp({
    title: (title: string) => `${title} - ${appName}`,
    progress: {
      color: '#4B5563',
    },

    resolve: (name: string) =>
      resolvePageComponent(
        `../pages/${name}.vue`,
        import.meta.glob<DefineComponent>('../pages/**/*.vue'),
      ),

    setup({ el, App, props, plugin }) {
      createApp({ render: () => h(App, props) })
        .use(plugin)
        .use(sentryVuePlugin)
        .component('inertia-link', Link)
        .component('InertiaLink', Link)
        .mount(el)
    },
  })
})
